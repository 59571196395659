import "./NftCollection.css"
import grid from "../Assets/avatars-grid.png"
import Faq from "react-faq-component";

export default function NftCollection({data}) {

    const styles = {
        bgColor: 'transparent',
        titleTextColor: "white",
        rowTitleColor: "white",
        rowContentColor: 'white',
        arrowColor: "white",
        rowTitleFontWeight: 200,
    };

    const config = {
        animate: true,
        arrowIcon: "—",
        tabFocus: true
    };


    return (
        <>
            <div className={"page-wrapper"} id={"collection"}>
                <div className="backdrop">
                    <img src={grid} alt="" className={"img-fluid"}/>
                </div>
                <div className="row h-100 align-items-center">
                    <div className="col-md-6">
                        <div className={"page-main-content"} data-aos="fade-up">
                            <h1 className={"display-5 fw-lighter text-white"}>{data?.MainTitle}</h1>
                            <p className={"fw-lighter text-white"}>
                                {data?.MainParagraph}
                            </p>

                            <h5 className={"fw-bold text-white"}>{data?.SecondTitle}</h5>
                            <Faq
                                data={data?.FAQs}
                                styles={styles}
                                config={config}
                            />

                            <div className={"mt-5"}>
                                <a className={"btn btn-outline-light rounded-pill custom-pill-button"}
                                   href={data?.CollectionLink} target={"_blank"}>
                                    View Collection
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 d-flex align-items-center justify-content-center">
                        <div className={"collection-image-wrapper"}>
                            <img src={grid} alt="" className={"img-fluid"} data-aos={"fade-right"}/>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}