import "./Story.css"
import body from "../Assets/watch_animated/body.png"
import glass from "../Assets/watch_animated/glass.png"
import hours from "../Assets/watch_animated/hours.png"
import mins from "../Assets/watch_animated/minutes.png"
import secs from "../Assets/watch_animated/seconds.png"


export default function Story({videoSwitch, data}) {
    // const [isOpen, setOpen] = useState(false)

    return (
        <>
            <div className={"page-wrapper"} id={"story"}>
                <div className="row h-100 align-items-center">
                    <div className="col-md-6">
                        <div className={"page-main-content"} data-aos="fade-up">
                            <h1 className={"display-5 fw-lighter text-white"}>{data?.MainTitle}</h1>
                            <p className={"fw-lighter text-white linebreak"}>
                                {data?.MainParagraph}
                            </p>
                            <h5 className={"fw-bold text-white"}>{data?.SecondTitle}</h5>
                            <p className={"fw-lighter text-white linebreak"}>
                                {data?.SecondParagraph}
                            </p>
                            <div className={"mt-5"}>
                                <button className={"btn btn-outline-light rounded-pill custom-pill-button"}
                                        onClick={() => {
                                            videoSwitch(true)
                                        }}
                                >View Video
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 d-flex align-items-center justify-content-center watch-animated-wrapper">
                        <div className="watch-animated" data-aos="fade-up">
                            <img src={body} alt="" className={"img-fluid"} id={"body"}/>
                            <img src={glass} alt="" className={"img-fluid"} id={"glass"}/>
                            <img src={hours} alt="" className={"img-fluid"} id={"hours"}/>
                            <img src={mins} alt="" className={"img-fluid"} id={"minutes"}/>
                            <img src={secs} alt="" className={"img-fluid"} id={"seconds"}/>
                        </div>
                    </div>
                </div>

            </div>

        </>
    )
}