import "./Contact.css"
import grid from "../Assets/contact-bg.png"
import {useState} from "react";
import pullData from "../Utils/API";

export default function Contact({data, meta}) {
    const[message, setMessage] = useState("")
    const[name, setName] = useState("")
    const[email, setEmail] = useState("")

    const[sending, setSending] = useState(false)
    const [err, setErr] = useState(false)

    function handleFormSubmission(event){
        event.preventDefault();

        if (email === "" || message === "" || name === ""){
            setErr(true)
        }else{
            setErr(false)
            setSending(true)
            pullData.handleContactForm(name, email, message).then(r => {
                console.log(r)
                setSending(false)
            })
        }

    }

    return (
        <>
            <div className={"page-wrapper"} id={"contact"}>
                <div className={"backdrop"}>
                    <img src={grid} alt="" className={"img-fluid"}/>
                </div>
                <div className="row h-100 align-items-center">
                    <div className="col-md-6 offset-md-3 d-flex align-items-center justify-content-center mb-md-5">
                        <div className={"page-main-content"} data-aos="fade-up">
                            <h1 className={"display-5 fw-lighter text-white text-center"}>
                                {data?.MainTitle}
                            </h1>
                            <p className={"fw-lighter text-white text-center px-md-5 my-4"}>
                                {data?.MainParagraph}
                            </p>

                            <form action="#" onSubmit={event => handleFormSubmission(event)}>
                                <div className="row mb-4">
                                    <div className="col-md-6">
                                        <input type="text" placeholder={"Name"} className={"form-control"}
                                               onChange={event => setName(event.target.value)} required/>
                                    </div>
                                    <div className="col-md-6">
                                        <input type="email" placeholder={"Email"} className={"form-control"}
                                               onChange={event => setEmail(event.target.value)} required/>
                                    </div>
                                </div>
                                <div className="message-wrapper">
                                <textarea value={message} placeholder={"Add message"}
                                          onChange={event => setMessage(event.target.value)} className={"w-100"} required/>
                                </div>
                                <div>
                                    {err? <span className={"text-danger small"}>Please enter all details in the form.</span> : ""}

                                </div>
                                <div className={"text-end mt-4"}>
                                    <button className={"btn btn-outline-light rounded-pill custom-pill-button"}
                                            type={"submit"}
                                    >{sending ? "Sending.." : "Send Message"}</button>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
                <div className={"footer-contact"}>
                    <div className={"social"}>
                        <div className={"row"}>
                            <div className="col-6">
                                <a href={meta?.Facebook} className={"FBIG"}>FB</a>
                            </div>
                            <div className="col-6">
                                <a href={meta?.Instagram} className={"FBIG"}>IG</a>
                            </div>
                        </div>
                    </div>
                    <div className={"legal"}>
                        <a href={meta?.WhitePaper} className={"FBIG text-decoration-underline"}>White Sheets</a>
                        <a href={meta?.Terms_Conditions} className={"FBIG"}>Terms & conditions</a>
                    </div>
                </div>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
                <br/>
            </div>
        </>
    )
}