import HomePage from "../Pages/HomePage";
import Story from "../Pages/Story";
import NftCollection from "../Pages/NftCollection";
import TradeConcepts from "../Pages/TradeConcepts";
import Contact from "../Pages/Contact";

const Routes = [
    {
        name: "Welcome",
        slug: "#welcome",
        key: 1,
        component: <HomePage/>
    },
    {
        name: "Watch Story",
        slug: "#watch-story",
        key: 2,
        component: <Story/>
    },
    {
        name: "NFT Collection",
        slug: "#nft-collection",
        key: 3,
        component: <NftCollection/>
    },
    {
        name: "Trade Concept",
        slug: "#trade-concepts",
        key: 4,
        component: <TradeConcepts/>
    },
    {
        name: "Contact Us",
        slug: "#contact-us",
        key: 5,
        component: <Contact/>
    },

]

export default Routes;