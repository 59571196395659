import "./Homepage.css"
import watch from "../Assets/watch-1.png"
import pin from "../Assets/pin.png"
import avatar from "../Assets/avatar.png"
import eth from "../Assets/ETH-icon.png"
import chev from "../Assets/chevron-top-green.png"
import {useContext} from "react";
import ModalsContext from "../Utils/ModalsContext";
import AnimatedNumber from "animated-number-react";


export default function HomePage({data, meta, ethPrice}) {
    const {setModalOpen} = useContext(ModalsContext)

    return (
        <>
            <div className={"page-wrapper"} id={"homepage"}>
                <div className="row h-100 align-items-center">
                    <div className="col-md-6">
                        <div className={"page-main-content"} data-aos="fade-up">
                            <h1 className={"display-5 fw-lighter text-white"}>{data?.MainTitle}</h1>
                            <p className={"fw-lighter text-white"}>
                                {data?.MainDescription}
                            </p>

                            <div className={"mt-5 d-flex align-items-center"}>
                                <div className={"me-3"}>
                                    <a href={meta?.OwnerLink} className={"text-white"} target={"_blank"}>Already an
                                        owner?</a>
                                </div>
                                <div className={"mx-3"}>
                                    {meta?.AllowToBuy === false ?
                                        <button className={"btn btn-outline-light rounded-pill custom-pill-button"}
                                                onClick={() => {
                                                    setModalOpen(true)
                                                }}
                                        >Request to buy</button>
                                        :
                                        <button className={"btn btn-outline-light rounded-pill custom-pill-button"}
                                                onClick={() => {
                                                    setModalOpen(true)
                                                }}
                                        >Buy Now</button>
                                    }
                                </div>


                            </div>
                        </div>

                        <div className="limited-container" data-aos="fade-up">
                            <h2 className={"linebreak"}>{data?.WatchesHeading}</h2>
                            <p className={"fw-lighter text-white"}>{data?.WatchesDescription}</p>
                            <div className={"mt-4 row"}>
                                <div className="col-md-6">
                                    <a href={meta?.Facebook} className={"FBIG"}>FB</a>
                                </div>
                                <div className="col-md-6">
                                    <a href={meta?.Instagram} className={"FBIG"}>IG</a>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="col-md-6">
                        <div className="watch-1">
                            <div className={"position-relative"}>
                                <img src={watch} alt="" className={"img-fluid"}/>
                                <div className="stats">
                                    <div className="avatar">
                                        <img src={avatar} alt="" className={"img-fluid"}/>
                                    </div>
                                    <div className="numbers">
                                        <AnimatedNumber value={data?.USDPrice}
                                                        formatValue={
                                                            value => `$ ${Number(value).toFixed(2)}`
                                                        }
                                                        duration={400}/>
                                        <span className={"numbers d-flex align-items-center justify-content-center mb-0"}>
                                            ( <img src={eth} alt="" className={"img-fluid m-0"} style={{width: 22}}/>
                                            {`${(data?.USDPrice * ethPrice).toFixed(5)}`}
                                            )
                                        </span>

                                        <img src={chev} alt="" className={"img-fluid"}/>
                                    </div>
                                    <img src={pin} alt="" className={"img-fluid pin"}/>
                                    {/*<img src={gif} alt="" className={"img-fluid pin"}/>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
