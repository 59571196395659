import Routes from "../Utils/Routes";
import {Link} from "react-scroll";
import logo from "../Assets/logo-sidenav.png"
import {useEffect, useState} from "react";

export default function SideNavigation({meta}) {
    const[position, setPosition] = useState(window.location.hash)

    useEffect(()=>{
        window.addEventListener('scroll', ()=>{
            setTimeout(()=>{
                setPosition(window.location.hash)
            }, 200)
        })
    }, [position])


    return (
        <div className={"side-nav-wrapper"}>
            <div className="fixed">
                <aside className={"side-navigation"}>
                    <div className="logo">
                        <Link to={"/"}>
                            <img src={logo} alt="" className={"img-fluid"}/>
                        </Link>

                    </div>
                    <div className="dots d-flex align-items-center justify-content-center flex-column"
                         data-position={position}
                    >
                        {Routes.map((route, key) => {
                            return (
                                <Link
                                    key={key}
                                    to={route.slug.replace("#", "")}
                                    // hashSpy={true}
                                    // spy={true}
                                    offset={0}
                                    style={{cursor: "pointer"}}
                                    smooth={true} duration={200}

                                    className={position === route.slug && "active"}
                                >
                                    <span className={"dot"}/>
                                </Link>
                            )
                        })}
                    </div>
                    <div className="more">
                        <div className="wrapper">
                            <span className={"line"}/>
                            <a href={meta?.A22_Link} target={"_blank"}>Visit A22</a>
                        </div>

                    </div>

                </aside>
            </div>
        </div>
    )
}