import Routes from "../Utils/Routes";
import logo from "../Assets/logo-sidenav.png"
import burger from "../Assets/burger.png"
import {useState} from "react";
import {Link} from 'react-scroll'


export default function TopNavigation({modalSwitch, meta, howToSwitch}) {
    const [isMenuOpen, setMenuOpen] = useState(false)

    function handleClose(event) {
        setMenuOpen(false)
    }

    return (
        <>
            <nav className={"top-navigation fixed-top"}>
                <img src={logo} alt="" className={"brand"}/>
                <div className={isMenuOpen ? "wrapper show_mobile" : "wrapper"} onClick={e => handleClose(e)}>

                    <div className={"text-center d-none brand-wrapper"}>
                        <img src={logo} alt="" className={"brand"}/>
                    </div>
                    <div className="navigation">
                        {Routes.map((route, key) => {
                            return (
                                <Link
                                    key={key}
                                    to={route.slug.replace("#", "")}
                                    hashSpy={true}
                                    spy={true}
                                    offset={0}
                                    smooth={true} duration={200}
                                    className={"btn no-focus"}
                                    onClick={(e) => handleClose(e)}
                                >
                                    {route.name}

                                </Link>
                            )
                        })}
                    </div>
                    <div className="buy">
                        <Link
                            className={"btn no-focus text-decoration-underline"}
                            onClick={(e) => {
                                howToSwitch(true)
                                // handleClose(e)

                            }}
                        >
                            How to buy?
                        </Link>
                        {meta?.AllowToBuy === false ?
                            <button className={"button-green"} onClick={() => {
                                modalSwitch(c => !c)
                            }}>Request to buy
                            </button>
                            :
                            <button className={"button-green"} onClick={() => {
                                modalSwitch(c => !c)
                            }}>Buy Now
                            </button>
                        }

                    </div>

                </div>
                <div className="toggle">
                    <button onClick={() => setMenuOpen(c => !c)}><img src={burger} alt=""/></button>
                </div>
            </nav>
        </>
    )
}