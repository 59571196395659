let baseURL = "https://cms.arab-watches.com"
let apiURL = "https://apis.arab-watches.com"
let token = "9cca08ad59bf8af20edadcc666d8cd";
let requestOptions = {
    method: 'GET',
    redirect: 'follow'
};

let pullData = {
    pullHomepage: () => {
        let pageName = "WelcomePage";
        return fetch(`${baseURL}/api/singletons/get/${pageName}?token=${token}`, requestOptions)
            .then(response => response.text())
            .then(result => result)
            .catch(error => console.log('error', error));
    },
    pullMeta: () => {
        let pageName = "Meta";
        return fetch(`${baseURL}/api/singletons/get/${pageName}?token=${token}`, requestOptions)
            .then(response => response.text())
            .then(result => result)
            .catch(error => console.log('error', error));
    },
    pullCollection: () => {
        let pageName = "NFTCollection";
        return fetch(`${baseURL}/api/singletons/get/${pageName}?token=${token}`, requestOptions)
            .then(response => response.text())
            .then(result => result)
            .catch(error => console.log('error', error));
    },
    pullStory: () => {
        let pageName = "WatchStory";
        return fetch(`${baseURL}/api/singletons/get/${pageName}?token=${token}`, requestOptions)
            .then(response => response.text())
            .then(result => result)
            .catch(error => console.log('error', error));
    },
    pullConcept: () => {
        let pageName = "TradeConcept";
        return fetch(`${baseURL}/api/singletons/get/${pageName}?token=${token}`, requestOptions)
            .then(response => response.text())
            .then(result => result)
            .catch(error => console.log('error', error));
    },
    pullContact: () => {
        let pageName = "ContactUs";
        return fetch(`${baseURL}/api/singletons/get/${pageName}?token=${token}`, requestOptions)
            .then(response => response.text())
            .then(result => result)
            .catch(error => console.log('error', error));
    },
    pullETHPrice: () => {
        return fetch(`https://min-api.cryptocompare.com/data/price?fsym=USD&tsyms=ETH`, requestOptions)
            .then(response => response.text())
            .then(result => result)
            .catch(error => console.log('error', error));
    },
    handleContactForm: (name, email, message) => {
        let formdata = new FormData();
        formdata.append("clientName", name);
        formdata.append("clientEmail", email);
        formdata.append("clientMessage", message);
        let requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };
        fetch(`${apiURL}/contact.php`, requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));


        return fetch(`${baseURL}/api/forms/submit/ContactForm?token=${token}`, {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                form: {
                    clientName: name,
                    clientEmail: email,
                    clientMessage: message,
                }
            })
        })
            .then(entry => entry.json())
            .then(entry => entry)
            .catch(err => err)

    },
    handleBuyRequestForm: (form) => {
        let formdata = new FormData();

        console.log(form)
        Object.keys(form).forEach(key => {
            formdata.append(key.replaceAll(" ", ""), form[key]);
        })

        fetch(`${apiURL}/requesttobuy.php`, {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        }).then(r => {
            console.log(r)
        })

        return fetch(`${baseURL}/api/forms/submit/BuyRequestForm?token=${token}`, {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                form: form
            })
        })
            .then(entry => entry.json())
            .then(entry => entry)
            .catch(err => err)
    },
    handlePurchaseForm: (formdata) => {
        return fetch(`${apiURL}/transaction.php`, {
            method: 'post',
            body: formdata
        })
            .then(entry => entry.json())
            .then(entry => entry)
            .catch(err => err)
    }
}

export default pullData;
