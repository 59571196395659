import "./TradeConcepts.css"
import grid from "../Assets/tc-new.png"
import Faq from "react-faq-component";
import bg from "../Assets/background.gif"

export default function TradeConcepts({data}) {

    const styles = {
        bgColor: 'transparent',
        titleTextColor: "white",
        rowTitleColor: "white",
        rowContentColor: 'white',
        arrowColor: "white",
        rowTitleFontWeight: 200,
    };

    const config = {
        animate: true,
        arrowIcon: "—",
        tabFocus: true
    };


    return (
        <>
            <div className={"page-wrapper"} id={"trade-concepts"}>
                <div className="row h-100 align-items-center">
                    <div className="col-md-6 d-flex align-items-center justify-content-center">
                        <div className={"page-main-content"} data-aos="fade-up">
                            <h1 className={"display-5 fw-lighter text-white"}>
                                {data?.MainTitle}
                            </h1>
                            <p className={"fw-lighter text-white"}>
                                {data?.MainParagraph}
                            </p>

                            <h5 className={"fw-bold text-white"}>
                                {data?.SecondTitle}
                            </h5>
                            <Faq
                                data={data?.FAQ}
                                styles={styles}
                                config={config}
                            />

                        </div>
                    </div>
                    <div className="col-md-6 d-flex align-items-center justify-content-center position-relative">
                        <div className={"trade-image-wrapper position-relative d-flex align-items-center justify-content-center"}>
                            <img src={bg} alt="" className={"img-fluid background-waves position-absolute"}/>
                            <img src={grid} alt="" className={"img-fluid"} data-aos={"fade-left"}/>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}