import React, {createContext, useEffect, useState} from "react";
import SideNavigation from "../Components/SideNavigation";
import TopNavigation from "../Components/TopNavigation";
import {TransitionGroup, CSSTransition} from "react-transition-group";
import Modal from "../Components/Modal";
import {ModalProvider} from "../Utils/ModalsContext";
import HomePage from "../Pages/HomePage";
import Contact from "../Pages/Contact";
import TradeConcepts from "../Pages/TradeConcepts";
import NftCollection from "../Pages/NftCollection";
import Story from "../Pages/Story";
import AOS from "aos";
import "aos/dist/aos.css";
import ModalVideo from "react-modal-video";
import pullData from "../Utils/API";
import logo from "../Assets/logo-sidenav.png"


function App() {
    const [isModalOpen, setModalOpen] = useState(false)
    const [isScrollable, setScrollable] = useState(true)
    const [isVideoModalOpen, setVideoModalOpen] = useState(false)
    const [isHowToModalOpen, setHowToModalOpen] = useState(false)

    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);


    const [welcome, setWelcome] = useState(null)
    const [story, setStory] = useState(null)
    const [coll, setColl] = useState(null)
    const [concept, setConcept] = useState(null)
    const [contact, setContact] = useState(null)
    const [meta, setMeta] = useState(null)
    const [ETH, setETH] = useState(0)

    //Pull initial information
    useEffect(() => {
        pullData.pullHomepage().then(r => {
            setWelcome((JSON.parse(r)))
        })
        pullData.pullStory().then(r => {
            setStory((JSON.parse(r)))
        })
        pullData.pullConcept().then(r => {
            setConcept((JSON.parse(r)))
        })
        pullData.pullCollection().then(r => {
            setColl((JSON.parse(r)))
        })
        pullData.pullContact().then(r => {
            setContact((JSON.parse(r)))
        })
        pullData.pullMeta().then(r => {
            setMeta((JSON.parse(r)))
        })
        pullData.pullETHPrice().then(r => {
            setETH((JSON.parse(r).ETH))
        })
    }, [])

    //Update ETH Data
    useEffect(()=>{
        const interval = setInterval(()=>{
            pullData.pullETHPrice().then(r => {
                setETH((JSON.parse(r).ETH))
                console.log("Updating ETH Price")
            })
        }, 5000)

        return () => clearInterval(interval);
    }, [])

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (loading === true &&
            welcome !== null &&
            story !== null &&
            coll !== null &&
            concept !== null &&
            meta !== null &&
            contact !== null) {
            setLoading(false)
        }
    }, [welcome, story, coll, concept, contact, loading, meta])



    if (loading){
        return(
            <div className={"text-white bg-black p-5 text-center d-flex align-items-center justify-content-center min-vh-100 min-vw-100"}>
                <img src={logo} alt="Arab Watches" style={{maxWidth: 120}}/>
            </div>
        )
    }else{
        return (<>
                <div className={"App"}>
                    <ModalProvider value={{isModalOpen, setModalOpen, isVideoModalOpen, setVideoModalOpen}}>
                        <SideNavigation meta={meta}/>

                        <main className={isScrollable ? "content-container scrollable" : "content-container un-scrollable"}>
                            <TopNavigation modalSwitch={setModalOpen} meta={meta} howToSwitch={setHowToModalOpen}/>

                            <div className="scrolling-section">
                                <div id={"welcome"}>
                                    <HomePage data={welcome} meta={meta} ethPrice={ETH}/>
                                </div>
                                <div id={"watch-story"}>
                                    <Story videoSwitch={setVideoModalOpen} data={story}/>
                                </div>
                                <div id={"nft-collection"}>
                                    <NftCollection data={coll}/>
                                </div>
                                <div id={"trade-concepts"}>
                                    <TradeConcepts data={concept}/>
                                </div>
                                <div id={"contact-us"}>
                                    <Contact data={contact} meta={meta}/>
                                </div>
                            </div>
                        </main>

                        {/*Modal Containers*/}
                        <TransitionGroup>
                            <CSSTransition
                                key={isModalOpen}
                                classNames="fade"
                                timeout={300}
                            >
                                <>
                                    {isModalOpen && <Modal openState={isModalOpen}
                                                           setState={setModalOpen} meta={meta} data={welcome}
                                                           ethPrice={ETH}
                                    />}
                                </>

                            </CSSTransition>
                        </TransitionGroup>

                        <ModalVideo channel='youtube' isOpen={isVideoModalOpen} videoId={story?.YoutubeVideoID}
                                    autoplay
                                    onClose={() => setVideoModalOpen(false)}/>

                        <ModalVideo channel='youtube' isOpen={isHowToModalOpen} videoId={meta?.Howto_video_ID_Youtube}
                                    autoplay
                                    onClose={() => setHowToModalOpen(false)}/>

                    </ModalProvider>
                </div>
            </>
        );
    }

}

export default App;
