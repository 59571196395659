import "./Modal.css"
import React, {createRef, useEffect, useState} from "react";
import ChevronBack from "../Assets/chevron-back.png"
import {CSSTransition, SwitchTransition} from "react-transition-group";
// import buyHeader from "../Assets/buy-modal-header.png"
import metamask from "../Assets/metamask-logo.png"
import pullData from "../Utils/API";
import detectEthereumProvider from "@metamask/detect-provider";
import MerkleTree from 'merkletreejs';
import keccak256 from 'keccak256';
import Web3 from "web3";
import abi from './../abi.json'
import connectmetamask from "../Assets/connect-metamask.png"
import buyheader from "../Assets/buyheader.png"


require("dotenv").config();

const {REACT_APP_CONTRACT_ADDRESS} = process.env;

const SELECTEDNETWORK = "1";
const SELECTEDNETWORKNAME = "Ethereum";
const nftquantity = 50;

export default function Modal({openState, setState, meta, data, ethPrice}) {
    const [page, setPage] = useState(0)
    const page2Ref = createRef()
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [mobile, setMobile] = useState("")
    const [country, setCountry] = useState("")
    const [city, setCity] = useState("")
    const [address, setAddress] = useState("")
    const [quantity, setQuantity] = useState("1")

    const [page1Enabled, setPage1Enabled] = useState(false)
    const [page3Enabled, setPage3Enabled] = useState(false)

    const [sendText, setSendText] = useState("Submit")


    // -------------- >

    const [counter, setCounter] = React.useState(0);
    const [showCounder, setShowCounder] = React.useState(false);
    const [showConnectWallet, setShowConnectWallet] = React.useState(false);


    const [errormsg, setErrorMsg] = useState(false);
    // const [quantityNFT, setQuantityNFT] = useState(1);
    const [totalSupply, settotalSupply] = useState(0);
    const [status, setStatus] = useState(0);
    const [account, setAccount] = useState('')
    const [isConnected, setisConnected] = useState(false);
    const [isLoading, setisLoading] = useState(false);

    const [value, setValue] = useState(0);
    const [HexProofMain, setHexProofMain] = useState(null);

    let security = [
        // codes for proof.
        "THIS IS CODE",
        "81739827389238",
        "236723782738927893",
    ]


    //Web 3 Code
    async function connectMetaMask() {
        if (!window.ethereum) {
            window.location.assign("https://metamask.app.link/dapp/https://arab-watches.com/#welcome")
            return
        }

        if (process.browser) {
            const ethereum = window.ethereum;
        }

        await window.ethereum
            .request({method: "eth_requestAccounts"})
            .then(handleAccountsChanged)
            .catch((err) => {
                if (err.code === 4001) {
                    // EIP-1193 userRejectedRequest error
                    // If this happens, the user rejected the connection request.
                    console.log("Please connect to MetaMask.-----");
                    setErrorMsg("Please connect to MetaMask.");
                } else {
                    console.error(err);
                }
            });
    }

    async function handleAccountsChanged(accounts) {
        if (accounts.length === 0) {
            // MetaMask is locked or the user has not connected any accounts
            console.log("Please connect to MetaMask.");
            setErrorMsg("Please connect to MetaMask.");

        } else if (accounts[0] !== account) {
            let metaMaskAccount = accounts[0];
            console.log(metaMaskAccount);
            if (metaMaskAccount) {
                setAccount(metaMaskAccount);
                fetch(metaMaskAccount);
            } else {
                setErrorMsg("Connect Your wallet");
            }
        } else {
            let metaMaskAccount = accounts[0];
            setAccount(metaMaskAccount);
            fetch(metaMaskAccount);

        }
    }

    async function fetch(metamakAcc) {

        setisLoading(true)
        window.web3 = new Web3(window.ethereum);
        const web3 = window.web3;
        if (await web3.eth.net.getId() == SELECTEDNETWORK) {

            const contractaddress = REACT_APP_CONTRACT_ADDRESS;
            const ct = new web3.eth.Contract(abi, contractaddress);
            settotalSupply(await ct.methods.totalSupply().call());

            if (nftquantity - await ct.methods.totalSupply().call() == 0) {
                setErrorMsg("All NFTs minted, Sale has ended");
            }

            let statusValue = await ct.methods.getStatus().call()

            console.log("status", statusValue)
            if (statusValue === '0') {
                console.log("Sale is not Active");
                setErrorMsg("SALE IS NOT ACTIVE");
                setisLoading(false)
                return;
            } else if (statusValue === '1') {
                setStatus(1);
                console.log("Public-Sale on");
                await hash();
                return;
            }

        } else {
            setErrorMsg("Select \"" + SELECTEDNETWORKNAME + "\" network in your wallet to buy the nft");
        }
    }

    async function hash() {

        const leafNodes = security.map(addr => keccak256(addr));
        const markleTreeT = new MerkleTree(leafNodes, keccak256, {sortPairs: true});

        const rootHash = markleTreeT.getHexRoot();
        const codeChecker = keccak256("THIS IS CODE").toString('hex')
        const HexProof = markleTreeT.getHexProof(codeChecker);

        if (HexProof.length > 0) {
            setHexProofMain(HexProof)
            setisLoading(false)
            setisConnected(true);
        } else {
            setisLoading(false);
            setErrorMsg("Sorry , You are not white listed");
        }
    }

    function parseDataForTransport(data) {
        const formData = new FormData();

        let form = {
            "firstname": firstName,
            "lastname": lastName,
            "phone": mobile,
            "email": email,
            "country": country,
            "city": city,
            "fulladdress": address,
            "buy_quantity": quantity,
            "blockchain_data": JSON.stringify(data),
            "watch_id": data.totalSupply,
            "fromWallet": data.transactionResponse.from
        }

        Object.keys(form).forEach(key => {
            formData.append(key, form[key])
        })

        pullData.handlePurchaseForm(formData).then(r => {
            console.log(r)
            setSendText("Sent!")
            setTimeout(setState(false), 3000)
        })
    }

    async function loadWeb3Public() {
        setQuantity("1")

        if (await detectEthereumProvider()) {
            window.web3 = new Web3(window.ethereum);
            await window.ethereum.enable();
            const web3 = window.web3;
            // Meta Mask Connected Account Address

            if (await web3.eth.net.getId() == SELECTEDNETWORK) {
                // // creating contract instance
                const contractaddress = REACT_APP_CONTRACT_ADDRESS;
                const ct = new web3.eth.Contract(abi, contractaddress);

                let current = await ct.methods.totalSupply().call();
                if (Number(current) === nftquantity) {
                    console.log("Sold out");
                    return;
                }

                //We set this to zero for testing.
                let check = (data?.USDPrice * ethPrice).toString()

                let price = (web3.utils.toWei(check, 'ether')) * quantity
                await ct.methods.mint(quantity, HexProofMain, "THIS IS CODE").send({from: account, value: price})
                    .then((res) => {
                        console.log("RES ---- > ", res)
                        alert("Token minted! After receiving confirmation from your wallet, " +
                            "you can see it at Opensea or on your wallet")
                        ct.methods.totalSupply().call().then(r => {
                            parseDataForTransport({
                                totalSupply: r,
                                transactionResponse: res
                            });
                        })
                    }).catch(function (e) {
                        if (JSON.stringify(e).indexOf("is not Active") > -1) {
                            alert("Sorry, sale is not active right now.")
                        } else if (JSON.stringify(e).indexOf("Need to send more ETH.") > -1) {
                            alert("Sorry, seems your wallet doesn't have enough funds to make this purchase!")
                        } else if (JSON.stringify(e).indexOf("can not mint this many") > -1) {
                            alert("Sorry, purchase would exceed limit per wallet!")
                        } else if (JSON.stringify(e).indexOf("reached max supply") > -1) {
                            alert("Sorry, sold out!")
                        } else if (JSON.stringify(e).indexOf("not authorized to mint") > -1) {
                            alert("not authorized to mint!")
                        } else {
                            alert("Sorry, we had an error with your transaction. " + e.message)
                        }
                    });

                settotalSupply(await ct.methods.totalSupply().call());
                setQuantity(1);
            } else {
                setErrorMsg("Select \"" + SELECTEDNETWORKNAME + "\" network in your wallet to buy the nft")
            }
        } else if (window.web3) {
            window.web3 = new Web3(window.web3.currentProvider);
        } else {
            {
                setErrorMsg("browser not detected. You should consider trying MetaMask!")
            }
        }
    }

    //Web 3 Code

    // useEffect(() => {
    //     page2Ref.current.scroll({top: 0, behavior: 'smooth'})
    // }, [page, page2Ref])


    useEffect(() => {
        if (firstName !== "" && lastName !== "" && address !== "" && mobile !== "" && email !== "" && city !== "" && country !== "") {
            setPage1Enabled(true)
        } else {
            setPage1Enabled(false)
        }

        if (quantity !== "") {
            setPage3Enabled(true)
        } else {
            setPage3Enabled(false)
        }
    }, [address, city, country, email, firstName, lastName, mobile, quantity])

    function handleFormSubmit() {
        setSendText("Submitting...")
        let form = {
            "First Name": firstName,
            "Last Name": lastName,
            "Mobile Number": mobile,
            "Email Address": email,
            "Country Name": country,
            "City Name": city,
            "Full Address": address,
            "Buy Quantity": quantity,
        }
        console.log("Handling form submit")

        if (meta.AllowToBuy) {
            //todo: handle this...
            console.log("Proceeding with buy request.")

        } else {
            console.log("not allowed to buy.. Sending request in cms")
            pullData.handleBuyRequestForm(form).then(r => {
                console.log(r)
                setSendText("Sent!")
                setTimeout(setState(false), 3000)
                alert("Data sent!")

            })
        }

    }


    //FRONTEND CODE STARTS HERE

    console.log(account)

    function page1() {
        return (<div className={"modal-content border-0 p-md-4"}>
            <div className={"container"}>
                <h2 className={"h2 fw-lighter"}>Awesome, get started</h2>
                <span className={"fw-lighter"}>Let’s get to know you a bit better</span>
            </div>
            <div className="container">
                <div className={"row my-3"}>
                    <div className="col-md-6 mb-3">
                        <input type="text" placeholder={"First Name"}
                               value={firstName}
                               onChange={(e) => {
                                   setFirstName(e.target.value)
                               }}
                               className={"form-control"}/>
                    </div>
                    <div className="col-md-6 mb-3">
                        <input type="text" placeholder={"Last Name"} className={"form-control"}
                               value={lastName}
                               onChange={(e) => {
                                   setLastName(e.target.value)
                               }}/>
                    </div>
                    <div className="col-md-6 mb-3">
                        <input type="email" placeholder={"Email"} className={"form-control"}
                               value={email}
                               onChange={(e) => {
                                   setEmail(e.target.value)
                               }}/>
                    </div>
                    <div className="col-md-6 mb-3">
                        <input type="text" placeholder={"Country code - Mobile number "} className={"form-control"}
                               value={mobile}
                               onChange={(e) => {
                                   setMobile(e.target.value)
                               }}
                        />
                    </div>
                </div>
            </div>
            <div className={"container my-4"}>
                <div className={"divider"}/>
            </div>
            <div className="container">
                <span className="fw-lighter">Enter your delivery address now and will ship it free of charge</span>
                <p className={"fw-bold my-3"}>Delivery Address</p>
                <div className={"row my-3"}>
                    <div className="col-md-6 mb-3">
                        <input type="text" placeholder={"Country"}
                               value={country}
                               onChange={(e) => {
                                   setCountry(e.target.value)
                               }}
                               className={"form-control"}/>
                    </div>
                    <div className="col-md-6 mb-3">
                        <input type="text" placeholder={"City"}
                               value={city}
                               onChange={(e) => {
                                   setCity(e.target.value)
                               }}
                               className={"form-control"}/>
                    </div>
                    <div className="col-md-12 mb-3">
                        <input type="text" placeholder={"Full Address"}
                               value={address}
                               onChange={(e) => {
                                   setAddress(e.target.value)
                               }}
                               className={"form-control"}/>
                    </div>
                </div>
            </div>
            <div className="container my-4">
                <div className="row align-items-center">
                    <div className="col-md-6 mb-3">
                        <a href={meta?.Terms_Conditions} className={"text-black"}>View terms and conditons</a>
                    </div>
                    <div className="col-md-6 mb-3 d-flex align-items-center justify-content-end">
                        <button className={"custom-pill-button btn rounded-pill no-focus"}
                                onClick={() => setState(false)}>Cancel
                        </button>
                        {meta?.AllowToBuy === false ?
                            <button className={"custom-pill-button btn btn-dark rounded-pill"}
                                    disabled={!page1Enabled}
                                    onClick={() => {
                                        handleFormSubmit()
                                    }}>{sendText}
                            </button>
                            :
                            <button className={"custom-pill-button btn btn-dark rounded-pill"}
                                    disabled={!page1Enabled}
                                    onClick={() => setPage(1)}>Next
                            </button>
                        }
                    </div>
                </div>
            </div>

        </div>)
    }

    function page2() {
        return (<div className={"modal-content border-0 p-md-4"}>
            <div>
                <img src={ChevronBack} alt="" className={"img-fluid"} style={{height: 22, width: 11, cursor: "pointer"}}
                     onClick={() => {
                         setPage(0)
                     }}/>
            </div>
            <br/>
            <div className={"container"}>
                <div className="row">
                    <div className="col-md-8">
                        <h2 className={"h2 fw-lighter"}><span className={"text-capitalize"}>{firstName}</span>, Let’s do
                            this</h2>
                        <p className={"fw-lighter"}>If you already have an metamask or on openSea account you can buy
                            any NFT and unlock the code that you would enter here to redeam your watch</p>

                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-8">
                        <div className={"row my-2"}>
                            <div className="col-md-8 mb-3">
                                <input type="text" placeholder={"Enter Code"} className={"form-control"}/></div>
                        </div>
                    </div>
                </div>

            </div>
            <div className={"container my-2"}>
                <div className={"divider"}/>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-8">
                        <span className="fw-lighter">Or Buy the watch with a debit or credit card and we will link it to one of our NFT of your choice</span>
                        <p className={"fw-bold my-3"}>Checkout with your card</p>

                        <div className={"row mt-3"}>
                            <div className="col-md-8 mb-3">
                                <input type="text" placeholder={"Enter Card Number"} className={"form-control"}/>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className="col-md-4 mb-3">
                                <input type="text" placeholder={"Expiry Date"} className={"form-control"}/>
                            </div>
                            <div className="col-md-4 mb-3">
                                <input type="text" placeholder={"CVV"} className={"form-control"}/>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="container my-2">
                <div className="row align-items-center justify-content-end">
                    <div className="col-md-6 d-flex align-items-center justify-content-end">
                        <button className={"custom-pill-button btn rounded-pill no-focus"}
                                onClick={() => setState(false)}>Cancel
                        </button>
                        <button className={"custom-pill-button btn btn-dark rounded-pill"} onClick={() => {
                            setState(false)
                        }}>BUY
                        </button>
                    </div>
                </div>
            </div>

        </div>)
    }

    function page3() {
        if (account === "") {
            return accountNotConnected()
        } else {
            return accountConnected()
        }
    }


    function accountNotConnected() {
        return (<div className={"modal-content border-0 p-md-4"}>
            <div className={"container"}>
                <div className="row align-items-center mb-3">
                    <div className="col-1">
                        <img src={ChevronBack} alt="" className={"img-fluid"}
                             style={{height: 22, width: 11, cursor: "pointer"}}
                             onClick={() => {
                                 setPage(0)
                             }}/>
                    </div>
                    <div className="col-10">
                        <h2 className={"h2 fw-lighter mb-0"}>Connect Your Metamask</h2>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-8 offset-md-2 my-5 py-3">
                        <img src={connectmetamask} alt="" className={"img-fluid w-100"}/>
                    </div>
                </div>

            </div>
            <div className="container mt-3">
                <div className="row">
                    <div className="col-md-12" style={{fontSize: 13}}>
                        <p><strong className={"h4"}>To buy and mint, connect your wallet</strong></p>
                        <p className={"fw-light"}>
                            Make sure you create or already have a metamask account and you must have the MetaMask
                            extension installed in your browser.
                            <br/>
                            <br/>
                            Go to MetaMask.io and click the “Download” button, which will take you to the relevant store
                            to download the extension or app based on the device and browser you’re using.
                        </p>

                    </div>
                </div>

            </div>
            <div className="container mt-4">
                <div className="row align-items-center">
                    <div className="col-md-6 text-start small">
                        <a href="#" className={"text-black p-0 text-decoration-none"}>Powered by
                            <span className={"text-decoration-underline"}>A22</span>
                        </a>
                    </div>
                    <div className="col-md-6 d-flex align-items-center justify-content-end p-0 small">
                        <button className={"custom-pill-button btn rounded-pill no-focus"}
                                onClick={() => setState(false)}>Cancel
                        </button>
                        {isConnected ?
                            <>
                                <button className={"custom-pill-button btn btn-dark rounded-pill"}
                                        disabled={!page3Enabled}
                                        onClick={
                                            () => loadWeb3Public()
                                        }>BUY & Mint
                                </button>
                            </>

                            : <>
                                <button className={"custom-pill-button btn btn-dark rounded-pill"}
                                        onClick={
                                            () => connectMetaMask()
                                        }>Connect
                                </button>
                            </>

                        }


                    </div>
                </div>
            </div>

        </div>)

    }

    function accountConnected() {
        return (<div className={"modal-content border-0 p-md-4"}>
            <div className={"container"}>
                <div className="row align-items-center">
                    <div className="col-1">
                        <img src={ChevronBack} alt="" className={"img-fluid"}
                             style={{height: 22, width: 11, cursor: "pointer"}}
                             onClick={() => {
                                 setPage(0)
                             }}/>
                    </div>
                    <div className="col-10">
                        <h2 className={"h2 mb-0 fw-lighter"}><span className={"text-capitalize"}>{firstName}</span>, Let’s do
                            this</h2>
                    </div>
                </div>
            </div>
            <br/>
            <div className="container">
                <img src={buyheader} alt="" className={"img-fluid w-100"}/>
            </div>
            <div className="container mt-3">
                <div className="row">
                    <div className="col-md-8" style={{fontSize: 13}}>
                        <p><strong className={"h6"}>{errormsg}</strong></p>
                        <p><strong className={"h6"}>Watch Price {(data?.USDPrice * ethPrice).toFixed(5)} ETH
                            or {data?.USDPrice} USD</strong></p>
                        <p className={"fw-light"}>

                            Item Price {(data?.USDPrice * ethPrice).toFixed(5)} ETH or {data?.USDPrice} USD
                            <br/>
                            {isConnected && <>
                                Number of items purchased {totalSupply} Items of {nftquantity}
                            </>
                            }

                            <br/>
                            10 transactions per wallet
                        </p>

                        <div className={"wallet-metamask"}>
                            <span>{account}</span>
                            <img src={metamask} alt=""/>
                        </div>
                    </div>

                    {isConnected &&
                        <div className="col-md-4 p-0 d-flex justify-content-center align-items-center">
                            <div className="box small h-100">
                                <input className={"display-6 fw-lighter w-100 text-center border-0 bg-white"}
                                       type={"number"}
                                       autoFocus
                                       min={1}
                                       max={1}
                                       onBlur={(e) => {
                                           setQuantity("1")
                                       }}
                                       value={quantity}
                                       onChange={(e) => {
                                           setQuantity(e.target.value)
                                       }}
                                       placeholder={"-"} disabled/>
                                <hr/>
                                <span className={"text-muted fw-lighter small"}>Quantity</span>
                            </div>
                        </div>
                    }
                </div>

            </div>
            <div className="container mt-4">
                <div className="row align-items-center">
                    <div className="col-md-6 text-start small">
                        <a href="#" className={"text-black p-0 text-decoration-none"}>Powered by
                            <span className={"text-decoration-underline"}>A22</span>
                        </a>
                    </div>
                    <div className="col-md-6 d-flex align-items-center justify-content-end p-0 small">
                        <button className={"custom-pill-button btn rounded-pill no-focus"}
                                onClick={() => setState(false)}>Cancel
                        </button>
                        {isConnected ?
                            <>
                                <button className={"custom-pill-button btn btn-dark rounded-pill"}
                                        disabled={!page3Enabled}
                                        onClick={
                                            () => loadWeb3Public()
                                        }>BUY & Mint
                                </button>
                            </>

                            : <>
                                <button className={"custom-pill-button btn btn-dark rounded-pill"}
                                        onClick={
                                            () => connectMetaMask()
                                        }>Connect
                                </button>
                            </>

                        }


                    </div>
                </div>
            </div>

        </div>)

    }

    return (<>
        <div className={"modal-wrapper"} onClick={(e) => {
            if (e.target.classList.contains("modal-wrapper")) {
                setState(false)
            }
        }}>
            <div className="modal-body" ref={page2Ref}>
                <SwitchTransition>
                    <CSSTransition
                        key={page}
                        addEndListener={(node, done) => {
                            node.addEventListener("transitionend", done, false);
                        }}
                        classNames="fade"
                    >
                        {page === 0 ? page1() : page3()}
                    </CSSTransition>
                </SwitchTransition>

            </div>
        </div>
    </>)
}
